<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input placeholder="输入活动名称" class="search-input" v-model="searchObj.keyword"></el-input>
          <el-select
            placeholder="请选择状态"
            class="search-input ml-15"
            v-model="searchObj.activityStatus"
          >
            <el-option
              v-for="(item, index) in activity_status"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <el-link type="primary" :underline="false">
                <el-button class="ml-15" type="primary" @click="addActivity">添加活动</el-button>
              </el-link>
            </div>
          </div>
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="活动序号" width="140">
              <template slot-scope="scope">{{scope.$index+1+(pageNum-1)*pageSize}}</template>
            </el-table-column>
            <el-table-column label="活动名称" prop="activityName"></el-table-column>
            <el-table-column label="开始预告时间点" prop="noticeTime"></el-table-column>
            <el-table-column label="活动开始时间点" prop="activityStartTime"></el-table-column>
            <el-table-column label="活动结束时间点" prop="activityEndTime"></el-table-column>
            <el-table-column label="活动状态">
              <template
                slot-scope="scope"
              >{{String(scope.row.activityStatus)?getStatus(scope.row.activityStatus):'--'}}</template>
            </el-table-column>
            <el-table-column label="操作" width="350">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <!-- scope.row.activityStatus==0?false:true -->
                  <router-link
                    :to="{ name: 'Flashsale_new', params: {id: scope.row.id, addFlag: false, checkDetails: true} }"
                  >
                    <el-link type="primary" :underline="false">
                      <i :class="scope.row.activityStatus==0?'el-icon-edit-outline':'el-icon-view'"></i>
                      {{scope.row.activityStatus==0?'编辑':'详情'}}
                    </el-link>
                  </router-link>
                  <router-link
                    :to="{ name: 'Flashsale_order_list',  query:{activityId: scope.row.id }}"
                    class="ml-15"
                    v-if="scope.row.activityStatus>1"
                  >
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-info"></i>
                      查看订单
                    </el-link>
                  </router-link>
                  <span
                    class="tip-text-info ml-15"
                    @click="getGoods(scope.row.id,scope.row.activityStatus)"
                  >
                    <i class="el-icon-info"></i>
                    查看活动商品
                  </span>
                  <span
                    v-if="scope.row.activityStatus<3"
                    class="tip-text-delete ml-15"
                    @click="toEnd(scope.row.id, scope.row.activityStatus)"
                  >
                    <i class="el-icon-delete"></i>
                    终止
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="showGoods" width="70%">
      <div class="pad-container">
        <el-table class="mt-24 member-table" :data="goodsTable">
          <el-table-column label="商品条形码" prop="barCode"></el-table-column>
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <el-avatar :src="picsPath(scope.row.imgUrl)" shape="square" alt="加载失败"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column label="商品名称" prop="goodsName"></el-table-column>
          <el-table-column label="所属门店" prop="storeName"></el-table-column>
          <el-table-column label="原价" prop="markPrice"></el-table-column>
          <el-table-column label="实价" prop="price"></el-table-column>
          <el-table-column label="抢购价" prop="buyActivityPrice"></el-table-column>
          <el-table-column label="销量" prop="sales"></el-table-column>
          <el-table-column label="剩余活动库存" prop="buyActivityStock"></el-table-column>
        </el-table>
        <div class="flx-row ali-c js-c mt-24">
          <Pagination
            :total="total_goods"
            :pageNum="pageNum_goods"
            :hideSizes="true"
            :type="'dialog'"
            :pageSize="pageSize_goods"
            :pageSizes="pageSizes_goods"
            @syncPageData="syncPageData_goods"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {getFlashsaleList, stopFlashsale, getActivityProcudtList } from "@/api/maketing/flashsale"
export default {
  name: "credits_list",
  components: {
    Pagination
  },
  data() {
    return {
      // 分页数据
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      // 商品分页数据
      total_goods: 0,
      pageNum_goods: 1,
      pageSize_goods: 5,
      pageSizes_goods: [5, 20, 30, 50, 100],
      // 顶部搜索栏数据
      searchObj: {
        keyword: "",
        activityStatus: null
      },
      //活动状态
      activity_status: [
        {
          id: 0,
          title: "等待预告"
        },
        {
          id: 1,
          title: "预告中"
        },
        {
          id: 2,
          title: "正在售卖"
        },
        {
          id: 3,
          title: "已结束"
        }
      ],
      storeList: [],
      // 优惠券表格数据内容
      tableData: [],
      // 会员-当前分页与总条数
      showGoods: false,
      goodsTable: [],
      showRule: false,
      tableHeader: [  // 商品列表表头
        {
          value: "商品条形码",
          key: "barCode"
        },
        {
          value: "商品图片",
          key: "imgUrl"
        },
        {
          value: "商品名称",
          key: "goodsName"
        },
        {
          value: "一级分类",
          key: "firstClassifyName"
        },
        {
          value: "二级分类",
          key: "secondClassifyName"
        },
        {
          value: "实价",
          key: "price"
        },
        {
          value: "库存",
          key: "stock"
        }
      ],
      addType: null,
      addFlag: true,
      goodsList: []
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData() {
      // // console.log("2");
      let params = {
        keyword: this.searchObj.keyword,
        activityStatus: this.searchObj.activityStatus,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }

      getFlashsaleList(params).then(res => {
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      })
    },
    // 添加活动
    addActivity() {
      sessionStorage.removeItem("CHECK_DETAILS");
      this.$router.push({ name: "Flashsale_new", params: { addFlag: true } });
    },
    // 搜索事件
    search() {
      this.pageNum = 1;
      this.initData();
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        keyword: "",
        activityStatus: null
      };
      this.pageNum = 1;
      this.initData();
    },
    // 获取本页商品
    getPageGoods() {
      this.goodsTable = this.goodsList.slice(
        (this.pageNum_goods - 1) * this.pageSize_goods,
        (this.pageNum_goods - 1) * this.pageSize_goods + this.pageSize_goods
      );
    },
    // 获取活动商品列表
    getGoods(id,activityStatus) {
      let params = {
        activityId: id,
        activityStatus
      };
      getActivityProcudtList(params).then(res => {
        console.log(res.data.body)
        this.pageNum_goods = 1;
        let data = res.data.body.activityGoodsVO;
        this.goodsList = data;
        this.getPageGoods();
        this.total_goods = data && data.length;
        this.showGoods = true;
      })
    },
    toEnd(id, activityStatus) {
      this.$confirm("是否终止该活动？终止后无法再次启用！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          stopFlashsale(id).then(res => {
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            setTimeout(() => {
              this.initData();
            }, 100);
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作"
          });
        });
    },
    //获取活动状态
    getStatus(val) {
      let temp = "";
      this.activity_status.forEach(e => {
        if (e.id == val) {
          temp = e.title;
        }
      });
      return temp;
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.initData();
    },
    syncPageData_goods(data) {
      this.pageNum_goods = data.num;
      this.pageSize_goods = data.size;
      this.getPageGoods();
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.el-avatar /deep/ img {
  width: 100%;
}
</style>
